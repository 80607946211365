#contactCard {
  opacity: 85%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 10px;
}

.myInfo {
  margin-left: 15px;
}
