.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#aboutMe {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  opacity: 85%;
}

#headShot {
  width: 20%;
  height: 20%;
  opacity: 100%;
  margin-top: -1px;
  margin-left: -1px;
}

#text1 {
  padding: 10px;
}

.tab {
  display: inline-block;
  margin-left: 40px;
}

nav {
  z-index: 1;
}

.background {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  .background {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}
